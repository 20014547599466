<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <b-container fluid style="padding-right: 12.5px; padding-left: 12.5px">
        <b-row class="my-1">
          <b-col sm="3">
            <b-row class="my-1">
              <label>账户名查询:</label>
            </b-row>
            <b-row class="my-1">
              <b-form-input
                v-model="search.name"
                placeholder="请输入账户名"
              ></b-form-input>
            </b-row>
          </b-col>

          <b-col sm="3">
            <b-row class="my-1">
              <label style="color: white">.</label>
            </b-row>
            <b-row class="my-1">
              <b-button
                variant="primary"
                @click="account2page()"
                style="margin-right: 10px"
                >搜 索</b-button
              >
              <b-button variant="success" @click.prevent="account2initAdd()"
                >新增账户</b-button
              >
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-3 pb-0">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-vertical-center">
          <thead>
            <tr>
              <th class="pl-0 text-center" style="width: 50px">#</th>
              <th class="pl-0" style="min-width: 200px">名称</th>
              <th class="pl-0" style="min-width: 100px">角色</th>
              <th class="pl-0" style="min-width: 125px">状态</th>
              <th class="pl-0" style="min-width: 150px">操作</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in accountList">
              <tr v-bind:key="i">
                <!-- 序号 -->
                <td class="pl-0 text-center">
                  {{ i + 1 }}
                </td>

                <!-- 所属机构 -->
                <td class="pl-0">
                  <a
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >
                    {{ item.name }}
                  </a>
                  <div>
                    <span class="font-weight-bolder">所属机构:</span>
                    <a class="text-muted font-weight-bold text-hover-primary">
                      {{ item.orgzFullName }}
                    </a>
                  </div>
                </td>

                <!-- 角色 -->
                <td class="pl-0">
                  <span
                    v-for="role in item.roleList"
                    :key="role.id"
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ role.name }}
                  </span>
                </td>

                <!-- 状态 -->
                <td class="pl-0">
                  <span
                    class="label label-lg label-inline"
                    v-bind:class="`label-light-success`"
                  >
                    <!-- {{item.status}} danger 已禁用 -->
                    已启用
                  </span>
                </td>

                <td class="pl-0">
                  <!-- 设置启用禁用 -->
                  <a class="btn btn-icon btn-light btn-sm" title="启用禁用">
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        title="启用禁用"
                        src="media/svg/icons/General/Settings-1.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <!-- 编辑 -->
                  <a
                    class="btn btn-icon btn-light btn-sm mx-3"
                    title="修改"
                    @click.prevent="account2initEdit(item.id)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        title="修改"
                        src="media/svg/icons/Communication/Write.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <!-- 删除 -->
                  <a
                    class="btn btn-icon btn-light btn-sm"
                    title="删除"
                    @click.prevent="account2del(item.id)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        title="删除"
                        src="media/svg/icons/General/Trash.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <!-- 翻页按钮 -->
        <div class="mt-3">
          <b-pagination
            limit="7"
            first-number
            last-number
            size="lg"
            align="right"
            v-model="search.currentPage"
            :per-page="pageSize"
            :total-rows="rows"
          ></b-pagination>
        </div>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->

    <LcxAccountAdd ref="accountAdd" @account2page="account2page"></LcxAccountAdd>
  </div>
</template>

<style lang="scss">
.my-1 {
  padding-right: 10px;
}
</style>

<script>
let that;
import Swal from "sweetalert2";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

import Account from "@/assets/js/pages/account/account.js";
import LcxAccountAdd from "@/view/pages/account/include/account-add.vue";

export default {
  name: "account-list",

  data() {
    return {
      search: {
        currentPage: 1,
        name: ""
      },
      rows: 100,
      pageSize: 5,
      accountList: []
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  components: {
    LcxAccountAdd
  },
  watch: {
    "search.currentPage": function () {
      // console.log('currentPage change to %d from %d', newV, oldV);
      this.account2page();
    }
  },
  mounted() {
    that = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "账户管理", route: "" },
      { title: "账户列表" }
    ]);
    this.account2page();

    
  },
  methods: {
    account2page: function () {
      Account.list(this.search).then(({ data }) => {
        var pageInfo = data.pageInfo;
        var accountList = data.accountList;

        that.accountList = accountList;

        that.pageSize = pageInfo.pageSize;
        that.search.currentPage = pageInfo.pageNum;
        that.rows = pageInfo.total;
      });
    },
    account2initAdd: function () {
      this.$refs.accountAdd.account2initAdd();
    },
    account2initEdit: function (id) {
      this.$refs.accountAdd.account2initEdit(id);
    },
    account2del: function (id) {
      Swal.fire({
        title:
          '<span style="color: black;">删除操作不可恢复，是否确定删除？<span>',
        showCancelButton: true,
        confirmButtonText: `确定`,
        cancelButtonText: `取消`
      }).then(result => {
        if (result.isConfirmed) {
          Account.del(id).then(({ msg }) => {
            // 重新加载页面列表
            this.account2page();
            // 消息弹框
            window.Toast("success", msg);
          });
        }
      });
    }
  }
};
</script>
