<template>
    <v-app>
        <v-row justify="center">
        <v-dialog
            v-model="accountDialog.dialog"
            persistent
            scrollable
            max-width="600px"
        >
            <v-form
            ref="form"
            lazy-validation
            >
            <v-card>
                <!-- 表单头部 -->
                <v-toolbar dark color="primary" v-lcx-drag>
                <v-toolbar-title>{{ accountDialog.title }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="accountDialog.dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-toolbar>

                <v-card-text>
                <v-container style="padding-top: 10px">
                    <v-row>
                    <!-- 所属机构 -->
                    <v-col cols="12" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >所属机构*</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                            id="kt_login_forgot"
                        ></span>
                        </div>
                        <div>
                        <v-select
                            outlined
                            item-text="name"
                            item-value="id"
                            v-model="currAccount.orgzId"
                            :items="accountDialog.orgzList"
                            :rules="[myRules.selected]"
                            dense
                        ></v-select>
                        </div>
                    </v-col>

                    <!-- 用户名 -->
                    <v-col cols="12" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >用户名*</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                            id="kt_login_forgot"
                            >不能使用中文，只能包含字母、数字、下划线</span
                        >
                        </div>
                        <div>
                        <v-text-field
                            type="input"
                            name="name"
                            v-model="currAccount.name"
                            autocomplete="off"
                            @change="remoteName(currAccount.name)"
                            :rules="[myRules.remoteNameValid]"
                            append-icon="mdi-account"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>

                    <!-- 密码 -->
                    <v-col cols="12" v-if="accountDialog.isAdd">
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >密码</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field 
                            type="password"
                            name="password"
                            v-model="accountDialog.password"
                            autocomplete="off"
                            append-icon="mdi-lock"
                            @change="pwd2Validate()"
                            :rules="[myRules.required, myRules.len_6_20]"
                            outlined
                            dense
                        ></v-text-field>
                        </div>
                    </v-col>

                    <!-- 确认密码 -->
                    <v-col
                        v-if="accountDialog.isAdd"
                        cols="12"
                    >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >确认密码</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-text-field ref="pwd2"
                            type="password"
                            name="password2"
                            v-model="accountDialog.password2"
                            autocomplete="off"
                            :rules="[myRules.required, myRules.equalPwd]"
                            dense
                            append-icon="mdi-lock"
                            outlined
                        ></v-text-field>
                        </div>
                    </v-col>

                    <!-- 角色 -->
                    <v-col cols="12" >
                        <div class="d-flex justify-content-between mt-n5">
                        <label
                            class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >角色</label
                        >
                        <span
                            class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                        </div>
                        <div>
                        <v-row>
                            <v-col
                            v-for="role in accountDialog.roleList"
                            :key="role.id"
                            cols="12"
                            sm="4"
                            md="4"
                            >
                            <v-checkbox
                                color="success"
                                v-model="currAccount.roleIdList"
                                :label="role.name"
                                :value="role.id"
                                hide-details
                                dense
                            ></v-checkbox>
                            </v-col>
                        </v-row>
                        </div>
                    </v-col>
                    </v-row>
                </v-container>
                <span style="margin-top: 10px; display: block;"
                    class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    >带 * 号的是必填项</span
                >
                </v-card-text>

                <!-- start:按钮区 -->
                <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn class="ma-2" color="success" type="submit" @click.prevent="account2save()"> 保存 </v-btn>

                <v-btn
                    color="blue-grey"
                    class="ma-2 white--text"
                    @click="accountDialog.dialog = false"
                >
                    关闭
                </v-btn>
                </v-card-actions>
                <!-- end:按钮区 -->
            </v-card>
            </v-form>
        </v-dialog>
        </v-row>
    </v-app>
</template>

<style lang="scss">
</style>

<script>
let that;
import Account from "@/assets/js/pages/account/account.js";

export default {
  name: "LcxAccountAdd",
  data() {
    return {
      myRules: {
        selected: v => !!v || '必选',
        required: v => !!v || '不能为空',
        equalPwd: v => (v == this.accountDialog.password) || '两次密码输入不一致',
        len_6_20: v => (v.length >= 6 && v.length <= 20) || "长度至少6位，最多20位",
        remoteNameValid: false
      },
      currAccount: {
        id: "",
        name: "",
        password: "",
        orgzId: "",
        roleIdList: []
      },
      accountDialog: {
        title: "新增账户", // accountDialog.title = "新增账户"
        password: "",
        password2: "",
        dialog: false,
        isAdd: false,
        roleList: [],
        orgzList: []
      }
    };
  },
  mounted() {
      that = this;
      this.getOrgzList();
  },
  methods: {
    getOrgzList: function () {
      Account.getOrgzList().then(({ data }) => {
        that.accountDialog.orgzList = data;
      });
    },
    pwd2Validate: function() {
      if (this.$refs.pwd2) {
        this.$refs.pwd2.validate();
      }
    },
    remoteName: function (v) {
      that.myRules.remoteNameValid = false;

      if (!v) {
        that.myRules.remoteNameValid = '不能为空';
        return
      }

      that.myRules.remoteNameValid = '等待校验';

      Account.validateName(that.currAccount).then(({ data }) => {
        that.myRules.remoteNameValid = data
          ? true
          : v + " 已经经被占用，不能重复！";
      });
    },
    resetAccount: function () {
      this.currAccount.id = "";
      this.currAccount.name = "";
      this.currAccount.password = "";
      this.currAccount.orgzId = "";
      this.currAccount.roleIdList = [];
      
      this.accountDialog.password = "";
      this.accountDialog.password2 = "";
    },
    account2save: function () {
      if(!this.$refs.form.validate()){
        return false;
      }
      Account.save(that.currAccount, that.accountDialog).then(({ msg }) => {
        // 关闭模式窗口
        that.accountDialog.dialog = false;
        // 重新加载页面列表
        // that.$parent.account2page(); // 不推荐使用parent
        that.$emit('account2page');
        // 消息弹框
        window.Toast("success", msg);
      });
    },
    account2initAdd: function () {
      that.resetAccount();

      Account.selectRoleList().then(({ data }) => {
        // 模式窗口 - 新增初始化
        that.accountDialog.roleList = data;
        that.accountDialog.dialog = true;
        that.accountDialog.title = "新增账户";
        that.accountDialog.isAdd = true;

        if (that.accountDialog.orgzList.length > 0) {
          that.currAccount.orgzId = that.accountDialog.orgzList[0].id;
        }

        that.remoteName(that.currAccount.name);

        if (this.$refs.form && this.$refs.form.resetValidation) {
          this.$refs.form.resetValidation();
        }
      });
    },
    account2initEdit: function (id) {
      that.resetAccount();

      Account.findById(id).then(({ data }) => {
        // 模式窗口 - 修改初始化
        that.currAccount = data.account;
        that.accountDialog.roleList = data.roleList;
        that.accountDialog.dialog = true;
        that.accountDialog.title = "修改账户";
        that.accountDialog.isAdd = false;

        that.remoteName(that.currAccount.name);

        if (this.$refs.form && this.$refs.form.resetValidation) {
          this.$refs.form.resetValidation();
        }
      });
    },
  }
};
</script>